<template>
    <div class="main_holder users">
        <div class="content_holder">
            <div class="content">
                <h2>Seznam uporabnikov</h2>

                <div class="user_list_exports">
                    <div v-if="getUserType == 2" class="as_link icon export_item" title="Izvoz vseh uporabnikov" @click="exportUsers()">
                        <font-awesome-icon class="color_zip" :icon="['far', 'file-excel']" />
                    </div>    
                    <div v-if="isUserAccessEvalvation" class="as_link icon export_item" title="Izvoz dostopov do programov" @click="exportProgramAccessP()">
                        <font-awesome-icon :icon="['far', 'file-excel']" />
                    </div> 
                </div>

                <div class="container">
                    <div class="table-wrapper user_list">
                        <div class="search-wrapper">
                            <div class="buttonBarSearch_holder">
                                <!-- User Search bar -->
                                <div class="Search_Bar">
                                    <input
                                        type="text"
                                        class="search_bar_width"
                                        v-model="search"
                                        placeholder="Išči"
                                        @keydown="currentPage = 1;"
                                    />
                                </div>

                                <!-- Add user button -->
                                <div class="button_add_position">
                                    <button
                                        type="button"
                                        class="button green"
                                        @click="
                                            showModal({
                                                component: 'users/AddUser',
                                            })
                                        "
                                    >
                                        Dodaj uporabnika
                                    </button>
                                </div>

                                <!-- Delete user button -->
                                <div class="button_remove_position">
                                    <button
                                        type="button"
                                        class="button gray"
                                        :class="{disabled: userIds.length == 0}"
                                        :disabled="userIds.length == 0"
                                        @click="
                                            showModal({
                                                component: 'users/DeleteUser',
                                                data: { id: userIds },
                                            })
                                        "
                                    >
                                        Odstrani uporabnike
                                    </button>
                                </div>
                            </div>
                        </div>

                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <!--<input
                                            type="checkbox"
                                            v-model="selectAll"
                                            @click="select"
                                        />-->
                                    </th>
                                    <th @click="sort('name')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Ime
                                    </th>
                                    <th @click="sort('surname')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Priimek
                                    </th>
                                    <th @click="sort('email')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Elektronska pošta
                                    </th>
                                    <th
                                        v-if="getUserType == 2"
                                        @click="sort('acronym')"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Fakulteta
                                    </th>
                                    <th @click="sort('type')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Tip
                                    </th>
                                    <th @click="sort('active')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Aktivnost
                                    </th>
                                    <th @click="sort('access_sections')">
                                        <font-awesome-icon
                                            :icon="['fas', 'sort']"
                                        />
                                        Modul
                                    </th>
                                    <th>Uredi</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(user, key) in sortedUsers"
                                    :key="key"
                                >
                                    <td>
                                        <input
                                            type="checkbox"
                                            v-model="userIds"
                                            :value="user.id"
                                        />
                                    </td>

                                    <td>{{ user.name }}</td>
                                    <td>{{ user.surname }}</td>
                                    <td>{{ user.email }}</td>
                                    <td v-if="getUserType == 2">
                                        {{
                                            getFaculties[user.faculty_id]
                                                .acronym
                                        }}
                                    </td>

                                    <td v-if="user.type == 1">upravitelj</td>
                                    <td v-else-if="user.type == 2">
                                        administrator
                                    </td>
                                    <td v-else-if="user.type == 3">
                                        pregledovalec
                                    </td>
                                    <td v-else>poročevalec/skrbnik</td>

                                    <td v-if="user.active == 1">
                                        <span class="status text-success"
                                            >•</span
                                        >
                                        Aktiven
                                    </td>
                                    <td v-else>
                                        <span class="status text-danger"
                                            >•</span
                                        >
                                        Neaktiven
                                    </td>
                                    <!-- Modul for which the user has permision to see -->
                                    <td>
                                        <label
                                            v-if="user.access_sections == 1"
                                            :disabled="
                                                user.access_sections == '1'
                                            "
                                        >
                                            Poslovno poročanje
                                            <br />
                                        </label>
                                        <label
                                            v-if="user.access_work_plan == 1"
                                            :disabled="
                                                user.access_work_plan == '1'
                                            "
                                        >
                                            Letni program dela
                                            <br />
                                        </label>
                                        <label
                                            v-if="user.access_evalvation == 1"
                                            :disabled="
                                                user.access_evalvation == '1'
                                            "
                                        >
                                            Samoevalvacija ŠP
                                        </label>
                                        <label
                                            v-if="
                                                user.access_evalvation != 1 &&
                                                user.access_sections != 1 &&
                                                user.access_work_plan != 1
                                            "
                                            style="color: red"
                                        >
                                            Pozor! Modul ni izbran!
                                        </label>
                                    </td>

                                    <td>
                                        <a
                                            v-if="
                                                getUserType == 2 ||
                                                user.type == 0 ||
                                                user.id == getUserID
                                            "
                                            class="edit"
                                            title="Uredi uporabnika"
                                            data-toggle="modal"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'pencil-alt']"
                                                @click="
                                                    showModal({
                                                        component:
                                                            'users/EditUser',
                                                        data: {
                                                            id: user.id,
                                                            faculty_id:
                                                                user.faculty_id,
                                                        },
                                                    })
                                                "
                                            />
                                        </a>

                                        <a
                                            v-if="
                                                getUserType == 2 ||
                                                user.type == 0
                                            "
                                            class="delete"
                                            title="Izbriši uporabnika"
                                            data-toggle="modal"
                                        >
                                            <font-awesome-icon
                                                :icon="['far', 'trash-alt']"
                                                @click="
                                                    showModal({
                                                        component:
                                                            'users/DeleteUser',
                                                        data: { id: user.id },
                                                    })
                                                "
                                            />
                                        </a>

                                        <a
                                            v-if="
                                                user.type == 0 &&
                                                user.access_evalvation == 1
                                            "
                                            class="delete"
                                            title="Uredi programe uporabnika"
                                            data-toggle="modal"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'th-list']"
                                                @click="
                                                    showModal({
                                                        component:
                                                            'users/EditUserPrograms',
                                                        data: {
                                                            id: user.id,
                                                            faculty_id:
                                                                user.faculty_id,
                                                            access_program_ids:
                                                                user.access_program_ids,
                                                            access_departments_ids:
                                                                user.access_oddelki_ids,
                                                        },
                                                    })
                                                "
                                            />
                                        </a>
                                    </td>
                                </tr>

                                <!-- pagination -->
                                <tr v-show="maxPage > 1">
                                    <td colspan="9">
                                        <ul-pagination-row
                                            :maxPage="maxPage"
                                            :currentPage="currentPage"
                                            @change_page="set_current_page"
                                        ></ul-pagination-row>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 

<script>
import { mapActions } from "vuex";
import PaginationRow from "../general/PaginationRow.vue";

export default {
    name: "usersList",
    components: {
        "ul-pagination-row": PaginationRow,
    },
    data: function () {
        return {
            search: "",
            selectAll: false,
            userIds: [],
            currentSort: "name",
            currentSortDir: "asc",
            currentPage: 1,
            perPage: 12,
            maxPage: 1,
        };
    },
    computed: {
        user_data() {
            this.userIds = [];
            
            let data = this.$store.getters.users_list_data
                ? this.$store.getters.users_list_data
                : {};

            let values = Object.values(data);
            return values;
        },

        getYear(){
            return this.$store.getters.year;
        },

        filteredRow: function () {
            return this.user_data.filter((user) => {
                if (Object.keys(this.getFaculties).length > 0) {
                    user.acronym = this.getFaculties[
                        user.faculty_id
                    ].acronym.toLowerCase();

                    //Dont show admins to manager
                    if (this.getUserType < 2 && user.type > 1) return false;

                    for (var key in user) {
                        if ((String(user[key]).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && this.getUserID != user.id)) {
                            return true;
                        }
                    }
                }
                return false;
            });
        },

        // Get all faculties
        getFaculties() {
            return this.$store.getters.getFaculties;
        },
        // Sortiranje tabele uporabnikov
        sortedUsers: function () {
            let col = Intl.Collator("sl");
            let res = this.filteredRow.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortDir === "desc") modifier = -1;
                if (col.compare(a[this.currentSort], b[this.currentSort]) < 0)
                    return -1 * modifier;
                if (col.compare(a[this.currentSort], b[this.currentSort]) > 0)
                    return 1 * modifier;
                return 0;
            });

            //Calculate number of all pages
            this.maxPage = Math.ceil(res.length / this.perPage);
            //Return only items on given page
            return res.slice(
                this.currentPage * this.perPage - this.perPage,
                this.currentPage * this.perPage
            );
        },

        is_programs_data() {
            let data = false;
            if (this.getUserType == 2)
                data =
                    Object.keys(this.$store.getters.evalvation_programs_all)
                        .length > 0;
            else
                data =
                    Object.keys(this.$store.getters.evalvation_programs_user)
                        .length > 0;

            return data;
        },

        is_department_data() {
            return Object.keys(this.$store.getters.evalvation_departments).length > 0;
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
        isUserAccessEvalvation() {
            return this.$store.getters.getUser.access_evalvation==1;
        },
    },
    methods: {
        ...mapActions([
            "getUserList",
            "getUserListByFaculty",
            "showModal",
            "addUser",
            "deleteUser",
            "getListsForUserAccess",
            "exportUsers",
            "exportProgramAccess",
        ]),
        select() {
            this.userIds = [];
            if (!this.selectAll) {
                for (let i in this.user_data) {
                    this.userIds.push(this.user_data[i].id);
                }
            }
        },
        sort: function (s) {
            //if s == current sort, reverse
            if (s === this.currentSort) {
                this.currentSortDir =
                    this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },

        //Change current page
        set_current_page(page) {
            this.currentPage = page;
        },

        exportProgramAccessP() {
            let post_data = { year: this.getYear };
            if(this.getUserType == 1){
                post_data.faculty = this.$store.getters.getFaculty.id;
            }
            console.log(post_data);
            this.exportProgramAccess(post_data);
        },
    },
    created() {
        if (this.getUserType == 2) {
            this.getUserList();
        } else if (this.getUserType == 1) {
            this.getUserListByFaculty();
        }

        this.getListsForUserAccess({year: this.getYear});
    },
};
</script>


<style lang="scss" scoped>
</style>
